.listProduct__btn {
  background: #0064C5;
  text-align: center;
  color: #fff;
  padding: 10px;
  font-weight: 700;
  width: 100%;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.listProduct__btn span {
  display: none;
}
.listProduct__btn .show {
  display: inline-block;
}

@media (min-width: 992px) {
  .listProduct__wrapper {
    display: flex;
  }

  .listProduct__left {
    flex: 0 0 250px;
    padding-right: 20px;
  }

  .listProduct__btn {
    display: none;
  }
}
@media (min-width: 1240px) {
  .listProduct__left {
    flex: 0 0 280px;
    padding-right: 50px;
  }
}
.listProduct__left ul {
  list-style: none;
  padding-bottom: 15px;
}
.listProduct__left .form__checkbox {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
}
.listProduct__left .form__checkbox label {
  font-size: 14px;
  color: #000;
}
.listProduct__left .form__checkbox input[type=radio] ~ label:before {
  top: 1px;
}
.listProduct__left .form__checkbox input[type=radio] ~ label:after {
  top: 6px;
}
.listProduct__left .form__checkbox > span {
  display: block;
  font-size: 14px;
  line-height: 1.4;
  color: #666;
}
.listProduct__left fieldset {
  margin-bottom: 17px;
}
.listProduct__left fieldset h3,
.listProduct__left fieldset h4 {
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 12px;
  font-style: italic;
}
.listProduct__left fieldset a {
  color: #000;
  text-decoration: none;
}

.is--desktop .listProduct__left label:hover {
  color: #0064C5;
}
.is--desktop .listProduct__left label:hover a {
  color: #0064C5;
}
.is--desktop .listProduct__left label:hover:before {
  border-color: rgba(0, 0, 0, 0.1);
}

.listProduct__nav fieldset {
  border: none;
  padding: 0;
}
.listProduct__nav fieldset:not(.listProduct__nav fieldset:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.listProduct__right {
  width: 100%;
}
.listProduct__right h2 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 400;
}

.listProduct [class*=col-] {
  margin-bottom: 30px;
}
@media (max-width: 576px) {
  .listProduct [class*=col-] {
    margin-bottom: 20px;
  }
}

@media (max-width: 992px) {
  .listProduct__nav {
    display: none;
    height: 200px;
    overflow: auto;
    margin-bottom: 30px;
  }
}