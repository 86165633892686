@import "partials/variables";

.listProduct__btn {
    background: $main;
    text-align: center;
    color: #fff;
    padding: 10px;
    font-weight: 700;
    width: 100%;
    margin-bottom: 20px;
    text-transform: uppercase;

    span {
        display: none;
    }

    .show {
        display: inline-block;
    }
}

//lista-produktow
@media(min-width: $grid-breakpoints-lg) {
    .listProduct__wrapper {
        display: flex;
    }

    .listProduct__left {
        flex: 0 0 250px;
        padding-right: 20px;
    }

    .listProduct__btn {
        display: none;
    }
}

@media(min-width: $grid-breakpoints-xl) {
    .listProduct__left {
        flex: 0 0 280px;
        padding-right: 50px;
    }
}

.listProduct__left {

    ul {
        list-style: none;
        padding-bottom: 15px;
    }

    .form__checkbox {

        display: flex;
        gap: 10px;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 10px;

        label {
            font-size: 14px;
            color: #000;
        }

        input[type=radio]~label:before {
            top: 1px;
        }

        input[type=radio]~label:after {
            top: 6px;
        }

        >span {
            display: block;
            font-size: 14px;
            line-height: 1.4;
            color: $txt-light;

        }

        // input[type=radio]:checked+label:after {
        //     height: 10px;
        //     width: 10px;
        //     top: 10px;
        //     left: 6px;
        // }
    }

    fieldset {
        margin-bottom: 17px;

        h3,
        h4 {
            text-transform: uppercase;
            font-size: 16px;
            margin-bottom: 12px;
            font-style: italic;
        }

        a {
            color: #000;
            text-decoration: none;
        }
    }
}

.is--desktop {
    .listProduct__left label:hover {
        color: $main;

        a {
            color: $main;
        }

        &:before {
            border-color: $line;
        }
    }
}

.listProduct__nav {
    fieldset {
        border: none;
        padding: 0;

        &:not(&:last-child) {
            border-bottom: 1px solid $line;
        }
    }
}

.listProduct__right {
    width: 100%;

    h2 {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 400;
    }
}


.listProduct [class*=col-] {
    margin-bottom: 30px;
    @media(max-width: $grid-breakpoints-sm) {
        margin-bottom: 20px; 
    }
}

//lista-produktow-end

@media(max-width: $grid-breakpoints-lg) {
    .listProduct__nav {
        display: none;
        height: 200px;
        overflow: auto;
        margin-bottom: 30px;
    }
}